import ArtSupplies from './ArtSupplies.jpg';
import Maneater from './Maneater.mp3';

import './App.css';

function App() {
  return (
    <div className="App">
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
      <header className="App-header">
        <script async src='https://www.googletagmanager.com/gtag/js?id=G-776C7GC4NN'></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-776C7GC4NN');`}
        </script>
          <img src={ArtSupplies} className="App-image fade-in" alt="EP art" />
        <p className="fade-in text-background">
          <code>Crooked Richard</code>
          <br/>
          <code>Art Supplies - EP</code>
          <code>Out Now on Spotify, Itunes, Apple Music, and Youtube Music</code>
          <br/>
          <code>
            Merch now available!
          </code>
          <a className="emailFont" href="https://doompop.creator-spring.com/" target="_blank"
          rel="noopener noreferrer">
            DoomPop Teespring Store
          </a>
          <br/>
          <br/>
          <iframe src="https://open.spotify.com/embed/album/0xJr1o0I83Bfh0eRNPozyN" width="100%" height="380" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
          <br/>
          <code>(Maneater (bonus cover site exclusive))</code>
          <br/>
          <audio controls>
            <source src={Maneater} type="audio/mpeg" />
            Maneater
          </audio>
          <br/>
          <a
          href="https://www.instagram.com/crookedrichard/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa fa-instagram icon-size fade-in"></i>
        </a>
        <a
          href="spotify:album:0xJr1o0I83Bfh0eRNPozyN"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa fa-spotify icon-size fade-in"></i>
        </a>
        <a
          href="http://itunes.apple.com/album/id/1581311737"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa fa-apple icon-size fade-in"></i>
        </a>
        <a className="emailFont" href="mailto:DoomPopLLC@protonmail.com">Email Us</a>
        </p>
      </header>
      <footer>Written by Seth Ruiz.
        Copyright DoomPop LLC 2021</footer>
    </div>
  );
}

export default App;
